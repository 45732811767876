import DataFilterHelper from './DataFilterHelper';
import {
  FILTER_TYPE_ADMISSION_BY_CARD,
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_PARTNER_MUSEUMS,
  FILTER_TYPE_SORT,
  FILTER_TYPE_TYPES,
} from '@/helpers/data-filter/DataFilterTypes';
import { isCurrentGeoPositionExist } from '@/helpers/GeolocationHelper';

class ArtSpaceDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_ADMISSION_BY_CARD]: null,
    [FILTER_TYPE_DESTINATIONS]: [],
    [FILTER_TYPE_TYPES]: [],
    [FILTER_TYPE_PARTNER_MUSEUMS]: null,
    [FILTER_TYPE_SORT]: null,
  };
  validValuesTypes = {
    array: [FILTER_TYPE_DESTINATIONS, FILTER_TYPE_TYPES],
    boolean: [FILTER_TYPE_ADMISSION_BY_CARD, FILTER_TYPE_PARTNER_MUSEUMS],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.destinations
   * @param {FilterGroupItem[]|null} groupsWithItems.types
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems.destinations,
        itemsType: FILTER_TYPE_DESTINATIONS,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems.types,
        itemsType: FILTER_TYPE_TYPES,
      }),
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_PARTNER_MUSEUMS,
        items: [
          {
            title: 'Show only partner museums',
            value: true,
          },
        ],
      }),
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_ADMISSION_BY_CARD,
        items: [
          {
            title: 'Show admission by card',
            value: true,
          },
        ],
      }),
    ];

    if (isCurrentGeoPositionExist()) {
      groups.push(
        this.addRadioGroupItems({
          itemsType: FILTER_TYPE_SORT,
          items: [
            {
              title: 'Around me',
              value: { column: 'distance' },
            },
          ],
        })
      );
    }

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(values = {}, fallback = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(values, fallback);

    return {
      filters: {
        artSpaceType: transformed[FILTER_TYPE_TYPES],
        destinationSlug: transformed[FILTER_TYPE_DESTINATIONS],
        hasAdmissionByDigitalCard: transformed[FILTER_TYPE_ADMISSION_BY_CARD],
        partnerMuseum: transformed[FILTER_TYPE_PARTNER_MUSEUMS],
      },
      sort: transformed[FILTER_TYPE_SORT],
    };
  }

  setDestinationsToValuesManually(destinations = []) {
    return { [FILTER_TYPE_DESTINATIONS]: destinations };
  }

  setShowPartnerMuseumsToValuesManually(showPartnerMuseums = null) {
    return { [FILTER_TYPE_PARTNER_MUSEUMS]: showPartnerMuseums };
  }
}

export default ArtSpaceDataFilterHelper.create();
