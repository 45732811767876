import DataFilterHelper from './DataFilterHelper';
import { FILTER_TYPE_SORT } from '@/helpers/data-filter/DataFilterTypes';
import { isCurrentGeoPositionExist } from '@/helpers/GeolocationHelper';

class DestinationMustSeeDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_SORT]: null,
  };

  /**
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups() {
    const groupItems = [
      {
        title: 'Sort alphabetically',
        value: { column: 'title' },
      },
    ];

    if (isCurrentGeoPositionExist()) {
      groupItems.unshift({
        title: 'Around me',
        value: { column: 'distance' },
      });
    }

    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_SORT,
        items: groupItems,
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      sort: transformed[FILTER_TYPE_SORT],
    };
  }
}

export default DestinationMustSeeDataFilterHelper.create();
